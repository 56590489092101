export const GET_STEPS = gql`
  query Steps(
    $applicantId: String!
    $pageInfo: PageInfoInput
    $filters: StepFiltersInput
  ) {
    steps(applicantId: $applicantId, pageInfo: $pageInfo, filters: $filters) {
      totalCount
      items {
        stepId
        createdAt
        updatedAt
        status
        type
        documents {
          documentId
          createdAt
          updatedAt
          deletedAt
          status
          userStatus
          uploadStatus
          category
          fileType
          expirationDate
          fileName
          path
          uploadUrl
          uploadedBy {
            employeeId
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const UPDATE_STEP = gql`
  mutation UpdateStep($id: String!, $input: UpdateStepInput!) {
    updateStep(id: $id, input: $input) {
      stepId
      createdAt
      updatedAt
      status
      type
      documents {
        documentId
        createdAt
        updatedAt
        deletedAt
        status
        userStatus
        uploadStatus
        category
        fileType
        expirationDate
        fileName
        path
        uploadUrl
      }
    }
  }
`

export const GET_STEPS_GROUP_BY_TYPE = gql`
  query StepsGroupByType(
    $applicantId: String!
    $stepTypes: [StepType!]
    $pageInfo: PageInfoInput
  ) {
    stepsGroupByType(
      applicantId: $applicantId
      stepTypes: $stepTypes
      pageInfo: $pageInfo
    ) {
      totalCount
      items {
        stepId
        createdAt
        updatedAt
        status
        type
        documents {
          documentId
          createdAt
          updatedAt
          deletedAt
          status
          userStatus
          uploadStatus
          category
          fileType
          expirationDate
          fileName
          path
          uploadUrl
          isRenewed
          uploadedBy {
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const GET_STEPS_METRICS = gql`
  query StepsMetrics($filters: StepFiltersInput) {
    stepMetrics(filters: $filters) {
      totalCount
      items {
        name
        value
      }
    }
  }
`
